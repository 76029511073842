$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

@import './StorefrontHome-mixins';

.section {
    &:not(.minimal) {
        @include section-primary();
    }
}
.content {
    max-width: 1248px;
    margin: auto;

    .minimal & {
        @include section();
        border-bottom: 1px solid $sassy-color-dolphin;
        padding-top: $sassy-spacing-x-large;
    }
}
.about {
    height: 100%;
    overflow: hidden;
    position: relative;
    margin-right: $sassy-spacing-x-large;
    transition: 250ms height ease;
}
.aboutUs {
    white-space: pre-line;
    transition: 250ms height ease;
    line-height: 2;
}
.divider {
    line-height: 20px;
    border-left: 1px solid $sassy-color-dolphin;
    margin: 0 $sassy-spacing-x-small;
}

.readMoreLess {
    cursor: pointer;
    margin-left: $sassy-spacing-x-small;
    color: $sassy-color-pitch-black;
    &:hover {
        color: $sassy-color-charcoal;
    }
}
.minimal .info {
    text-align: center;
}
.logo {
    display: block;
    max-width: 100%;
    margin-bottom: $sassy-spacing-small;
    border: 10px solid $sassy-color-white;

    :local(.minimal) & {
        border: 0;
        margin: $sassy-spacing-small auto;
    }
}
.yearJoined {
    @include sassy-font-header($size: none, $type: italic);
    line-height: 1;
    font-size: 14px;
    margin-bottom: $sassy-spacing-small;
    color: $sassy-color-satan;
}
.vatInfoLabel,
.associations {
    @include sassy-font-body($size: none, $type: heavy);
    color: $sassy-color-satan;
}
.vatInfo,
.association {
    @include sassy-font-body($size: small, $type: none);
    margin-bottom: $sassy-spacing-small;
    color: $sassy-color-satan;
}
.contact {
    @include sassy-font-body($size: small, $type: heavy);
    display: block;
    line-height: 1;
    margin-bottom: $sassy-spacing-small;
    &:focus {
        outline: none;
    }
}

// Tablet Styles
@include sassy-breakpoint-below(tablet-landscape) {
    .about {
        padding: 0 $sassy-spacing-small;
        margin-right: 0;
    }
}

// Mobile Styles
@include sassy-breakpoint-below(mobile) {
    .about {
        padding: 0 $sassy-spacing-small;
        margin-right: 0;
    }
    .info {
        text-align: center;
    }
    .logo {
        margin: $sassy-spacing-small auto;
    }
}
