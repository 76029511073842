$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-buyer-layout/exports/scss/_layout';
@import './StorefrontHome-mixins';

.section {
    @include section-primary();
    overflow: hidden;
}

.content {
    @include primary-page-width;
}
