$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

$button-size: 40px;

$share-icon-color: $sassy-color-white;
$social-icon-color: $sassy-color-noir;
$thank-you-color: $sassy-color-pitch-black;

$social-wrapper-width: $button-size * 3; // 3 social buttons total
$social-wrapper-height: $button-size * 2; // 2 rows first=socialIcons, second=thankYou

$time-to-open-menu: 175ms;
$time-to-roll-out-icons: 400ms;

// Top Node of Component
.container {
    position: relative;
    width: $button-size;
    height: $button-size;
}

// Content Wrapper
.wrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    transition: left $time-to-open-menu ease;
    :local(.isOpen) & {
        left: -$social-wrapper-width;
    }
}

// Wrapper around Social Buttons
.socialWrapper {
    margin: 0;
    position: absolute;
    width: $social-wrapper-width;
    height: $social-wrapper-height;

    background: $sassy-color-white;

    top: 0;
    right: -$social-wrapper-width;
    transition: all $time-to-open-menu ease;
    :local(.isOpen) & {
        right: $button-size; // shifts the white wrapper out to the left
    }

    :local(.showThanks) & {
        top: -$button-size; // shifts the white wrapper upwards
    }
}

// Share Button and Social Buttons
.button {
    width: $button-size;
    height: $button-size;
}

// Share Button
.share {
    position: relative;
    float: right;

    padding: $sassy-spacing-x-small;
    color: $share-icon-color;

    transition: background 175ms linear;
    &:hover {
        color: $sassy-color-noir;
        background: $sassy-color-white;
    }
    :local(.isOpen) & {
        color: $share-icon-color;
        background: $sassy-color-noir;
    }
}

// Social Buttons
.social {
    padding: 12px;
    color: $social-icon-color;

    transition: 75ms all linear;
    &:hover {
        transform: translateY(-5px);
        // need this because style defaults color of links to gold
        color: $social-icon-color;
    }
    &:active {
        transform: translateY(5px);
    }
}

// Social Button SVGs
.socialSvg {
    // Transitions to "roll out" the svg icons
    transition: $time-to-roll-out-icons all $time-to-open-menu ease;
    transform: translateX($social-wrapper-width) rotate(-150deg);
    :local(.isOpen) & {
        transform: translateX(0px) rotate(-360deg);
    }
}

// Thank You Message
.thankYouMessage {
    @include sassy-font-body($size: large, $type: none);
    color: $thank-you-color;

    text-align: center;
}
