$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-buyer-layout/exports/scss/_layout';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_buyerLinks';

// styles for desktop version
.links {
    width: 60%;
    margin: 0 auto $sassy-spacing-larger;
    // nesting .link to increase specificity for avoiding overrides
    .link {
        @include sassy-font-body($size: small, $type: none);
        padding: 0 0 $sassy-spacing-small;
        text-align: center;
    }
}

// styles for mobile version
.wrapper {
    @include primary-viewport-width;
    padding: $sassy-spacing-small $sassy-spacing-small $sassy-spacing-medium;
    border-top: $sassy-spacing-x-small solid $sassy-color-moonstone;
}

.header {
    @include sassy-font-header($size: medium);
    margin: 0 0 $sassy-spacing-small;
}

.innerWrapper {
    @include primary-viewport-width;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
        display: none;
    }
}

.item {
    @include sassy-buyer-standard-link($underline: none);
    @include sassy-font-body($type: heavy);
    display: flex;
    align-items: center;
    height: 40px;
    border: 1px solid $sassy-color-storm;
    padding: 0 $sassy-spacing-small;
    margin-right: $sassy-spacing-x-small;
    white-space: nowrap;
    color: $sassy-color-noir;
    &:last-child {
        margin-right: 0;
    }
    .noUnderline {
        text-decoration: none;
    }
}

.itemContainer {
    display: flex;
    padding: 0 $sassy-spacing-small $sassy-spacing-small;
    width: fit-content;
}
