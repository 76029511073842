$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_breakpoints';

/*
        MediaCarousel.jsx
     */
.mediaCarousel {
    width: 100%;
    display: flex;
    position: relative;
    align-items: stretch;
}
.prev,
.next {
    cursor: pointer;
    flex: 0 0 15%;
    display: flex;
    position: relative;
    align-items: center;
    color: $sassy-color-charcoal;

    transition: 250ms color ease;
    &:hover {
        color: $sassy-color-pitch-black;
    }

    :local(.mediaCarousel.disable) & {
        visibility: hidden;
    }
}
.prev {
    order: 0;
    margin-right: 30px;
    justify-content: flex-end;
}
.next {
    order: 2;
    margin-left: 30px;
    justify-content: flex-start;
}
.arrow {
    width: 50px;
    height: 50px;
    margin: 30px;
}
.carousel {
    order: 1;
    width: 70%;
    min-width: 0;
    min-height: 0;
    flex: 0 0 70%;

    position: relative;
    transform: translateX(0%);
    transition: 500ms transform ease;

    :local(.mediaCarousel.swipable) & {
        margin: auto;
    }
}
.carouselItems {
    display: flex;
    flex-wrap: nowrap;
}
.carouselItem {
    width: 100%;
    min-width: 0;
    min-height: 0;
    flex: 0 0 100%;
    padding: 0 10px;

    transition: 500ms opacity ease;
    transform: translate3d(0, 0, 0);
}

/*
        MediaItem.jsx
     */
.mediaWrapper {
    width: 100%;
    padding-top: 70%;
    position: relative;
    margin-bottom: 15px;
}
.mediaContainer {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;

    display: flex;
    align-items: center;
}
.photo {
    margin: auto;
    display: block;
    max-width: 100%;
    max-height: 100%;
    border: 1px solid $sassy-color-storm;
}
.video {
    width: 100%;
    height: 100%;
}
.caption {
    text-align: center;
}

// Tablet Styles
@include sassy-breakpoint-below(tablet-landscape) {
    .prev,
    .next {
        margin: 0;
    }
}

// Mobile Styles
@include sassy-breakpoint-below(tablet-portrait) {
    .arrow {
        margin: 10px;
    }
}
