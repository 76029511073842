$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.details {
    color: $sassy-color-white;
}
.nameWrapper {
    display: flex;
    align-items: center;
    margin-bottom: $sassy-spacing-x-small;
}
.companyName {
    @include sassy-font-header($size: x-large);
    margin: 5px 0 0;
    white-space: nowrap;

    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.locationModule {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}
.locationPin {
    width: 25px;
    height: 25px;
    margin-right: 5px;
}
.location {
    @include sassy-font-sub-header($type: normal);
    white-space: nowrap;
}
.sellerStats {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: $sassy-spacing-x-small;
    cursor: pointer;
}
.ratingFraction {
    @include sassy-font-body($size: small, $type: none);
    display: flex;
    margin-left: $sassy-spacing-x-small;
    margin-top: 3px;
    white-space: nowrap;
}

@include sassy-breakpoint-below(tablet-landscape) {
    .details {
        margin-left: 0;
        padding-top: $sassy-spacing-small;
    }
    .nameWrapper {
        flex-flow: column;
        align-items: center;
    }
    .companyName {
        order: 2;
    }
    .sellerStats {
        justify-content: center;
    }
    .locationModule {
        margin: 16px 0;
        justify-content: center;
    }
}

.goldDiamond {
    fill: $sassy-color-buyer-secondary;
}

.platinumDiamond {
    fill: $sassy-color-medal-platinum;
}

// Mobile Styles
@include sassy-breakpoint-below(mobile) {
    .locationModule {
        display: none;
    }
}
