@import '~dibs-buyer-layout/exports/scss/_layout';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

@mixin section() {
    @include sassy-font-body($type: none);
    padding: $sassy-spacing-small 0 $sassy-spacing-x-large;
}

@mixin section-primary() {
    @include section();
    @include primary-viewport-width;
    background: $sassy-color-buyer-tertiary;
}

@mixin footer() {
    margin-top: $sassy-spacing-x-large;
    text-align: center;
}
