$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_spacing';

$form-controls-height: 50px;
$input-country-code-width: 90px;
$input-country-code-field-proportion: 3 * 0.2;

:local {
    .form {
        background-color: $sassy-color-white;
        max-width: 600px;
        padding: $sassy-spacing-small $sassy-spacing-large $sassy-spacing-large;
        @include sassy-font-body($type: none);
        line-height: 1.5;

        p {
            margin: 0 0 $sassy-spacing-x-small;
        }
    }

    .inputsRow {
        display: flex;
        flex-direction: row;
        margin: $sassy-spacing-small 0;

        @include sassy-breakpoint-below(tablet-portrait) {
            margin: 22px 0 $sassy-spacing-medium 0;
        }
    }

    .countryCode {
        width: $input-country-code-width;
        flex: 0 0 $input-country-code-width;
        margin-right: $sassy-spacing-x-small;

        & .input {
            display: inline-block;
            width: $input-country-code-width * $input-country-code-field-proportion;
        }
    }

    .leftDecorator {
        margin-right: $sassy-spacing-x-small;
        background: $sassy-color-moonstone;
    }

    .plusSign {
        display: inline-block;
        width: $input-country-code-width * (1 - $input-country-code-field-proportion);
        text-align: center;
        color: $sassy-color-noir;
        background-color: $sassy-color-white;
        height: $form-controls-height;
        line-height: $form-controls-height;
        @include sassy-font-body($type: none);
        vertical-align: middle;

        $border: 1px solid $sassy-color-storm;
        border-bottom: $border;
        border-left: $border;
        border-top: $border;
    }

    .phoneNumber,
    .name {
        flex: 1;
    }

    .checkboxLabel {
        color: $sassy-color-noir;
    }

    .submit {
        width: 100%;
        margin: $sassy-spacing-x-small 0 $sassy-spacing-medium;

        &:focus {
            outline: none;
        }

        @include sassy-breakpoint-below(tablet-portrait) {
            margin: $sassy-spacing-x-small 0 $sassy-spacing-small;
        }
    }

    .inlineSpinner {
        display: inline-block;
        position: relative;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        line-height: 16px;
        vertical-align: middle;
    }

    .footer {
        @include sassy-font-body($type: none, $size: small);
        color: $sassy-color-iron;
    }

    .error {
        padding-bottom: $sassy-spacing-small;
        text-align: center;
        color: $sassy-color-alert-primary;
    }

    .allowCallbackPromptContainer {
        margin-bottom: $sassy-spacing-small;
        color: $sassy-color-white;
    }

    .allowCallbackDetails {
        margin-left: 38px;
        color: $sassy-color-iron;
    }
}
