$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.distinction {
    display: flex;
    position: relative;
    margin-left: 15px;
}
.copy {
    @include sassy-font-sub-header($size: small, $type: normal);
    white-space: nowrap;
    opacity: 0.7;
}
.icon {
    width: 17px;
    height: 17px;
    margin-right: $sassy-spacing-x-small;
}
.tooltipText {
    color: $sassy-color-satan;
}

@include sassy-breakpoint-below(tablet-landscape) {
    .distinction {
        @include sassy-font-body($size: x-small, $type: none);
        order: 1;
        margin-bottom: $sassy-spacing-x-small;
    }
}
