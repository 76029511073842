$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_zindex';

$icon-size: 26px;

.favoritesWrapper {
    position: absolute;
    top: 0;
    right: 0;
    padding: $sassy-spacing-x-small;
    @include sassy-z-index(bump);
}

.icon {
    width: $icon-size;
    height: $icon-size;
    margin-top: $sassy-spacing-x-small;
    transform: scale(0.8);

    &.enlargedIcon {
        transform: scale(1);
    }

    &.favoritesSpacing {
        margin-top: 0;
    }
}
