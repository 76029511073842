$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_buyerLinks';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.cover {
    // Stripped pattern covering banner photo
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0.8;
        background: url(https://a.1stdibscdn.com/assets/images/profile/darken_overlay_tile.png)
            repeat;
    }

    &.noPicture {
        .details,
        .tabsWrapper {
            margin-left: 0;
        }
    }
}

.top {
    padding-bottom: 95px;
}

.details {
    margin-left: $sassy-spacing-medium;
}

.headshot {
    transform: translateY(-20px);
}

.tabsWrapper {
    margin-left: $sassy-spacing-medium;
    position: relative;
    border-top: 1px solid $sassy-color-buyer-primary;
    height: 48px;
}

.follow {
    @include sassy-font-sub-header($type: normal);
    @include sassy-buyer-contrast-link-light();
    position: absolute;
    top: 0;
    right: 0;
    padding: 13px 0;
    cursor: pointer;
}

// Mobile Styles
@include sassy-breakpoint-below(tablet-landscape) {
    .profileCover {
        padding-top: $sassy-spacing-large;
    }
    .top {
        padding-bottom: $sassy-spacing-small;
    }
    .headshot {
        transform: none;
    }
    .details {
        margin-left: 0;
    }
    .tabsWrapper {
        margin-left: 0;
    }
}
