$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_animations';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_zindex';

@import './StorefrontHome-mixins';

.section {
    @include section();
    // needed to prevent .content.isPreview from covering entirety of document
    position: relative;
}
.content {
    @include sassy-font-body($size: x-small, $type: none);

    :local(.isPreview) &::after {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        position: absolute;
        @include sassy-z-index(overlay);
    }
}
.gridItem {
    margin-bottom: $sassy-spacing-large;
    @include sassy-breakpoint-above(desktop) {
        padding: 0 $sassy-spacing-small $sassy-spacing-small;
    }
}

.footer {
    @include footer();
}
