$device-type: 'responsive';
$user-type: 'buyer';

@import '~dibs-sassy/exports/_spacing';

.wrapper {
    white-space: nowrap;
}

.inlineWrapper {
    display: inline-block;
}

.star {
    display: inline-block;
}

.small + .small {
    margin-left: $sassy-spacing-x-small;
}

.medium + .medium {
    margin-left: $sassy-spacing-small;
}

.large + .large {
    margin-left: $sassy-spacing-medium;
}

.xSmall + .xSmall {
    margin-left: 3px;
}

.selectable {
    cursor: pointer;
}
