$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_zindex';

:local {
    .wrapper {
        position: relative;
        @include sassy-z-index(bump);
    }
    .cover {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center bottom;

        &.sticky {
            top: 0;
            left: 0;
            right: 0;
            position: fixed;

            .top {
                display: none;
            }
            .detailsWrapper {
                flex: 0 1 auto;
            }
            .tabsWrapper {
                flex: 1;
            }
            .socialWrapper {
                order: 3;
            }
        }
    }

    // Top Container
    .top {
        width: 94%;
        margin: 0 auto;
        max-width: 1440px;
        position: relative;
        @include sassy-z-index(bump);
    }

    // Bottom Container (Left and Right)
    .bottom {
        display: flex;

        width: 94%;
        margin: 0 auto;
        max-width: 1440px;
        position: relative;
        @include sassy-z-index(bump);
    }

    // Left Container (Picture)
    .left {
        position: relative;
    }
    .leftContent {
        width: 100%;
        position: relative;
        padding-bottom: 100%;
    }

    // Right Container (Details, Social, Tabs)
    .right {
        flex: 1;
        position: relative;

        .cover.noPicture & {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            position: absolute;
        }
    }
    .rightContent {
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        position: absolute;
        align-items: flex-end;
    }

    // Renderer Component Wrappers
    .pictureWrapper {
        width: 100%;
        height: 100%;
        position: absolute;
    }
    .detailsWrapper {
        flex: 1;
    }
    .socialWrapper {
        align-self: flex-end;
    }
    .tabsWrapper {
        // standard spacing increments are too large in this component (much of the
        // layout predates dibs-sassy)
        margin-top: 6px;
        flex: 0 0 100%;
    }

    // Mobile Styles
    @include sassy-breakpoint-below(tablet-landscape) {
        .bottom {
            display: block;
        }
        .left {
            margin: auto;
        }
        .cover.noPicture {
            .left {
                display: none;
            }
            .right {
                position: relative;
            }
        }
        .rightContent {
            display: block;
            position: relative;
        }
    }
}
