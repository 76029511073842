$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_breakpoints';

@keyframes :global(ring) {
    0% {
        transform: rotate(2deg);
    }
    10% {
        transform: rotate(-2deg);
    }
    40% {
        transform: rotate(2deg);
    }
    45% {
        transform: rotate(-4deg);
    }
    65% {
        transform: rotate(2deg);
    }
    100% {
        transform: rotate(2deg);
    }
}

@keyframes :global(shine) {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(0);
    }
    75% {
        transform: scale(1);
    }
    90% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

:global {
    .phone {
        animation: ring 2s ease-in-out;
        animation-iteration-count: infinite;
        transform-origin: center 65%;
    }

    .ray1,
    .ray2,
    .ray3,
    .ray4,
    .ray5 {
        animation: shine 2s ease-in-out;
        animation-iteration-count: infinite;
    }

    .ray1 {
        transform-origin: 30% 14%;
    }

    .ray2 {
        transform-origin: 50% 11%;
    }

    .ray3 {
        transform-origin: 68% 15%;
    }

    .ray4 {
        transform-origin: 85% 26%;
    }

    .ray5 {
        transform-origin: 17% 26%;
    }
}

:local {
    .wrapper {
        background-color: $sassy-color-buyer-tertiary;
    }

    .steps {
        display: flex;
        justify-content: space-evenly;
        text-align: center;
        padding: 0 $sassy-spacing-x-small $sassy-spacing-medium;
    }

    .step {
        margin: 0 $sassy-spacing-x-small;
        max-width: 216px;
    }

    .stepNumber {
        @include sassy-font-header($size: medium);
        margin-bottom: $sassy-spacing-x-small;
    }

    .stepDescription {
        @include sassy-font-body;
        color: $sassy-color-noir;
        line-height: 1.15;
    }

    .icon {
        max-width: $sassy-spacing-x-large;
    }

    .nextStepIcon {
        max-height: $sassy-spacing-x-large;
        max-width: $sassy-spacing-medium;
        fill: $sassy-color-iron;
    }

    .confirmationHeader {
        text-align: center;
        padding-bottom: $sassy-spacing-large;
    }

    .iconRinging {
        width: $sassy-spacing-x-large;
        margin-top: $sassy-spacing-small;
    }

    .headerWrapper {
        padding: $sassy-spacing-medium $sassy-spacing-large $sassy-spacing-small;
        color: $sassy-color-noir;
        text-align: center;
    }

    .modalBackButton {
        display: none;
        @include sassy-breakpoint-below(tablet-portrait) {
            display: block;
        }
    }
}
