$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

.content {
    width: 370px;
    padding: 0 $sassy-spacing-small;
}
.address,
.associations {
    margin-bottom: $sassy-spacing-small;
}
.addressLines {
    margin-bottom: $sassy-spacing-x-small;
}
.byAppointmentOnly {
    @include sassy-font-body($size: none, $type: italic);
}
.sectionHeader {
    @include sassy-font-body($type: heavy, $size: small);
}
