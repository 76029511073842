$device-type: 'responsive';
$user-type: 'buyer';

@import '~dibs-sassy/exports/_colors';

:local {
    .svg {
        //svg elements, which are inline, sit on the text baseline.
        //there is extra white space added below the svg to accomodate text decenders (the tail on 'y', 'g' etc).
        //vertical-align: top helps prevent the unwanted white space below this svg element.
        vertical-align: top;
    }
    .fullStar {
        fill: $sassy-color-tangerine-500;
    }
    .emptyStar {
        fill: $sassy-color-dolphin;
    }
    .gradientLeft,
    .gradientRight {
        stop-opacity: 1;
        stop-color: $sassy-color-dolphin;
    }
    .gradientLeft {
        stop-color: $sassy-color-tangerine-500; // same for dealer and internal
    }
    .large {
        height: 36px;
        width: 36px;
    }
    .medium {
        height: 26px;
        width: 26px;
    }
    .small {
        height: 18px;
        width: 18px;
    }
    .smaller {
        height: 14px;
        width: 14px;
    }
    .xSmall {
        height: 12px;
        width: 12px;
        vertical-align: baseline; // update the vertical alignment when stars are smaller than the text
    }
    .bordered {
        stroke: $sassy-color-tangerine-500;
        stroke-width: 10px;
    }
}
