$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';

:local {
    .prompt {
        background-color: $sassy-color-white;
        max-width: 600px;
        padding: $sassy-spacing-large;
        @include sassy-font-body($type: none);
        color: $sassy-color-satan;
    }

    .button {
        margin-top: $sassy-spacing-small;
    }
}
