$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';

.headshot {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $sassy-color-white;

    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid $sassy-color-buyer-primary;
}
.image {
    width: 100%;
    height: auto;
}
