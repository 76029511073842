$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_breakpoints';
@import '~dibs-sassy/exports/_fonts';

.social {
    display: flex;
    flex-wrap: nowrap;
}

.socialButton {
    $color: $sassy-color-white;
    $bgColor: $sassy-color-noir;
    @include sassy-font-sub-header($type: normal);
    cursor: pointer;
    width: 130px;
    margin-left: 10px;
    padding: 12px 30px;
    color: $color;
    border: 1px solid $color;
    line-height: 1;
    text-align: center;

    transition: 125ms background ease;
    &:hover {
        background-color: $color;
        color: $bgColor;
    }
}

.contact {
    background: $sassy-color-white;
    color: $sassy-color-noir;
    border-color: $sassy-color-white;
    &:hover {
        background: $sassy-color-noir;
        color: $sassy-color-white;
        border-color: $sassy-color-white;
    }
}

.shareButton {
    cursor: pointer;
    border: 1px solid $sassy-color-white;
}

.socialShareWrapper {
    margin: -1px;
}

// Tablet Styles
@include sassy-breakpoint-below(tablet-landscape) {
    .social {
        justify-content: center;
    }
}
// Mobile Styles
@include sassy-breakpoint-below(mobile) {
    .shareButton {
        display: none;
    }
}
